import { v4 as uuid } from 'uuid';
import { reactive } from 'vue';

export const ERROR = 'error';
export const WARNING = 'warning';
export const SUCCESS = 'success';
export const NOTICE = 'notice';

type NotificationType = 'error' | 'warning' | 'success' | 'notice';

const defaultTimeout: number = 8000;

const store = reactive({
  notifications: [] as {
    id: string;
    title: string;
    message: string | null;
    type: NotificationType | null;
    timeout?: number;
  }[],
  notify(
    title: string,
    message: string | null = null,
    type: NotificationType | null = null,
    timeout: number = defaultTimeout,
  ) {
    const id = uuid();
    let timer;

    if (timeout) {
      timer = setTimeout(() => this.dismiss(id), timeout);
    }

    this.notifications.push({
      id,
      title,
      message,
      type,
      timeout: timer,
    });
  },
  dismiss(id: string) {
    const index = this.notifications.findIndex((notification) => notification.id === id);

    clearTimeout(this.notifications[index].timeout);
    this.notifications.splice(index, 1);
  },
});

export default store;
