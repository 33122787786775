import useEnvStore from '@/store/modules/env';
import api from '@/utils/api';
import { defineStore } from 'pinia';
import { stringify } from 'qs';
import { computed, ref, watch } from 'vue';

type Purchase = {
  type: string;
  purchase_identifiers: {
    internal: any;
    visible: string;
    sparklayer: string;
    platform: string;
  };
  customer_identifiers: {
    sparklayer_impersonator: any;
    sparklayer: string;
    sparklayer_sales_rep: any;
    sparklayer_impersonator_assignee: string;
  };
  payment_method: string;
  dates: {
    updated_at: string;
    created_at: string;
    calculated_submitted_at: string;
    placed_at: string;
  };
  customer_reference: any;
  currency_code: string;
  calculated_shipping_address: string;
  calculated_fulfilment_status: string;
  calculated_total: {
    number: string;
    currency: string;
  };
  calculated_total_usd: {
    number: string;
    currency: string;
  };
};

type PurchaseCount = {
  total: {
    number: string;
    currency: string;
  };
  date: string;
  count: number;
};

type FilterParams = {
  dates?: {
    submitted?: {
      gte?: string;
      lte?: string;
    };
  };
  order_by?: string;
  type?: string;
};

export const DATE_OPTIONS = [
  { text: 'Last 7 days', value: 7 },
  { text: 'Last 30 days', value: 30 },
  { text: 'Last 90 days', value: 90 },
  { text: 'Custom', value: 'custom' },
] as const;

const INITIAL_DATE_OPTION = 30;

function formatPurchaseType(type: string): string {
  return type
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

function purchaseLink(purchase: Purchase): string | null {
  const envStore = useEnvStore();

  if (purchase.type !== 'order') {
    return null;
    // TODO: Implement purchase link for non-order purchases (DEV-1924)
    // switch (envStore.env?.platform) {
    //   case 'shopify':
    //     return `${envStore.env?.team.website}#spark-purchase-${purchase.purchase_identifiers.sparklayer}`;
    //   case 'bigcommerce':
    //     return `https://store-${envStore.env.store_id}.mybigcommerce.com#spark-purchase-${purchase.purchase_identifiers.sparklayer}`;
    //   default:
    //     return null;
    // }
  }

  switch (envStore.env?.platform) {
    case 'shopify':
      return `https://${envStore.env.store_id}/admin/orders/${purchase.purchase_identifiers.platform}`;
    case 'wix':
      return `https://manage.wix.com/dashboard/${envStore.env.store_id}/ecom-platform/order-details/${purchase.purchase_identifiers.platform}`;
    case 'bigcommerce':
      return `https://store-${envStore.env.store_id}.mybigcommerce.com/manage/orders/${purchase.purchase_identifiers.platform}`;
    default:
      return null;
  }
}

// set top of the moon for the browser locale
const now = new Date(new Date().setHours(0, 0, 0, 0));

export default defineStore('salesActivity', () => {
  const resetting = ref<boolean>(false);

  const currency = ref<string>('USD');

  const moneyFormatterWithoutPence = computed(
    () =>
      new Intl.NumberFormat([], {
        style: 'currency',
        currency: currency.value,
        maximumFractionDigits: 0,
      }),
  );

  const loading = ref<boolean>(true);

  const purchases = ref<Purchase[] | null>(null);
  const purchasesCount = ref<PurchaseCount[] | null>(null);

  const selectedPurchaseType = ref<string>('all');
  const purchaseTypes = ref<string[]>(['all']);

  const selectedDateOption = ref<7 | 30 | 90 | 'custom'>(INITIAL_DATE_OPTION);
  const dateRange = ref<{
    start: Date;
    end: Date;
  }>({
    // thirty days ago
    start: new Date(now.getTime() - INITIAL_DATE_OPTION * 24 * 60 * 60 * 1000),
    // today
    end: new Date(),
  });

  const showDatePicker = ref(false);

  const dateOptionFriendly = computed(() => {
    return DATE_OPTIONS.find((option) => option.value === selectedDateOption.value)?.text;
  });

  const purchaseTypeFriendly = computed(() => {
    return formatPurchaseType(selectedPurchaseType.value);
  });

  watch(selectedDateOption, async (newSelectedDateOption, oldSelectedDateOption) => {
    if (resetting.value) return;
    if (newSelectedDateOption === 'custom') {
      oldSelectedDateOption =
        oldSelectedDateOption === 'custom' ? INITIAL_DATE_OPTION : oldSelectedDateOption;

      dateRange.value = {
        // n days ago
        start: new Date(now.getTime() - oldSelectedDateOption * 24 * 60 * 60 * 1000),
        // yesterday
        end: new Date(new Date(now.getTime() - 24 * 60 * 60 * 1000).setHours(23, 59, 59, 59)),
      };

      showDatePicker.value = true;
    } else {
      showDatePicker.value = false;

      dateRange.value = {
        // n days ago
        start: new Date(now.getTime() - newSelectedDateOption * 24 * 60 * 60 * 1000),
        // today
        end: new Date(),
      };
    }
  });

  watch(selectedPurchaseType, () => {
    if (resetting.value) return;
    loadPurchases();
    loadPurchasesCount();
  });

  watch(dateRange, () => {
    if (resetting.value) return;
    loadPurchases();
    loadPurchasesCount();
    loadPurchaseTypes();
  });

  async function load() {
    if (purchases.value !== null) {
      return;
    }

    loading.value = true;

    loadPurchaseTypes();
    await loadPurchases();

    loading.value = false;
  }

  async function loadPurchaseTypes() {
    const filterParams: FilterParams = {
      dates: {
        submitted: {
          gte: dateRange.value.start.toISOString(),
          lte: dateRange.value.end.toISOString(),
        },
      },
    };

    const { data } = await api.sparkApi({
      method: 'GET',
      url: `/v1/purchases-count-by-type?${stringify(filterParams)}`,
    });

    purchaseTypes.value = ['all', ...Object.keys(data as Record<string, number>)];
  }

  async function loadPurchases() {
    loading.value = true;

    const filterParams: FilterParams = {
      dates: {
        submitted: {
          gte: dateRange.value.start.toISOString(),
          lte: dateRange.value.end.toISOString(),
        },
      },
      ...(selectedPurchaseType.value !== 'all' && {
        type: selectedPurchaseType.value,
      }),
      order_by: 'calculated_submitted_at',
    };

    const { data } = await api.sparkApi({
      method: 'GET',
      url: `/v1/purchases?${stringify(filterParams)}`,
    });

    purchases.value = data as Purchase[];

    loading.value = false;
  }

  async function loadPurchasesCount() {
    loading.value = true;

    const start = dateRange.value.start;
    const newStart = new Date(
      start.getTime() - 2 * (dateRange.value.end.getTime() - dateRange.value.start.getTime()),
    );

    const filterParams: FilterParams = {
      dates: {
        submitted: {
          gte: newStart.toISOString(),
          lte: dateRange.value.end.toISOString(),
        },
      },
      ...(selectedPurchaseType.value !== 'all' && {
        type: selectedPurchaseType.value,
      }),
    };

    const { data } = await api.sparkApi({
      method: 'GET',
      url: `/v1/purchases-count?${stringify(filterParams)}`,
    });

    purchasesCount.value = data as PurchaseCount[];

    currency.value = purchasesCount.value?.[0]?.total.currency ?? 'USD';

    loading.value = false;
  }

  function $reset() {
    resetting.value = true;
    currency.value = 'USD';
    loading.value = true;
    purchases.value = null;
    purchasesCount.value = null;
    selectedPurchaseType.value = 'all';
    purchaseTypes.value = ['all'];
    selectedDateOption.value = INITIAL_DATE_OPTION;
    dateRange.value = {
      // thirty days ago
      start: new Date(now.getTime() - INITIAL_DATE_OPTION * 24 * 60 * 60 * 1000),
      // today
      end: new Date(),
    };
    showDatePicker.value = false;
    resetting.value = false;
  }

  return {
    currency,
    moneyFormatterWithoutPence,
    loading,
    purchases,
    purchasesCount,
    selectedPurchaseType,
    purchaseTypes,
    purchaseTypeFriendly,
    selectedDateOption,
    DATE_OPTIONS,
    dateOptionFriendly,
    dateRange,
    showDatePicker,
    load,
    loadPurchases,
    loadPurchasesCount,
    loadPurchaseTypes,
    purchaseLink,
    formatPurchaseType,
    $reset,
  };
});
