import useEnvStore from '@/store/modules/env';
import notificationStore, { ERROR } from '@/store/modules/notifications';
import useSalesActivityStore from '@/store/modules/salesActivity';
import useSettingsStore from '@/store/modules/settings';
import api from '@/utils/api';
import * as Sentry from '@sentry/vue';
import { defineStore } from 'pinia';

type User = {
  user: {
    id: number;
    name: string;
    email: string;
    is_spark_staff: number;
  };
  autoLoginRestrictedSession: boolean;
  currentTeam: {
    id: number;
    name: string;
    is_partner: boolean;
    slugged_name: string;
    website: string;
    app_registration_source: string;
    billing_plan_name: any;
    shopify_recurring_trial_ends_at: any;
    shopify_enterprise_billing: boolean;
    shopify_enterprise_planned_go_live: any;
    billing_custom_price: any;
    orders_allowed: any;
    shopify_enterprise_billing_no_of_teams_allowed: any;
    billing_linked_team_id: any;
    shopify_enterprise_billing_completed: number;
    sales_agents_allowed: any;
    billing_duration: any;
    billing_provider: string;
    advanced_features_enabled: boolean;
    enterprise_features_enabled: boolean;
    billing_plan_data: any;
    billing_linked_site_id: any;
    is_scheduled_for_deletion: boolean;
  };
  intercomOptions: {
    app_id: string;
    name: string;
    email: string;
    user_id: number;
    is_staff: number;
    company: any;
    user_hash: string;
  };
  permissions: {
    updateBilling: boolean;
    manageAccount: boolean;
  };
  availableTeams: Team[];
};

type Team = {
  id: number;
  name: string;
  site_id: string;
  is_partner: boolean;
};

type State = {
  hasUserBeenLoaded: boolean;
  data: User | null;
  availableTeamsAdvanced: Team[];
};

export default defineStore('auth', {
  state: (): State => ({
    hasUserBeenLoaded: false,
    data: null,
    availableTeamsAdvanced: [],
  }),
  getters: {
    isLoggedIn: (state) => !!state.data?.user || false,
    user: (state) => state.data?.user,
    autoLoginRestrictedSession: (state) => state.data?.autoLoginRestrictedSession,
    name: (state) => state.data?.user?.name,
    firstName: (state) => state.data?.user?.name?.split(' ')[0] ?? state.data?.user?.name,
    isSparkStaff: (state) => state.data?.user?.is_spark_staff || false,
    email: (state) => state.data?.user?.email,
    acronymName: (state) =>
      state.data?.user?.name?.match(/\b\w/g)?.join('').toUpperCase().substring(0, 2),
    availableTeams: (state) => state.data?.availableTeams || [],
    hasPermission: (state) => (permission: keyof User['permissions']) =>
      state.data?.permissions?.[permission] || false,
  },
  actions: {
    setData(payload: User) {
      this.data = payload;
      this.hasUserBeenLoaded = true;
    },
    async loadUser(payload?: boolean) {
      if (!payload && this.hasUserBeenLoaded) {
        return;
      }
      try {
        const { data } = await api.get('/user');
        this.data = data;
        if (data.currentTeam) {
          if (data.currentTeam.slugged_name) {
            Sentry.setTag('site-id', data.currentTeam.slugged_name);
          }
        }
        // Intercom Setup
        if (data?.intercomOptions) {
          window.Intercom('boot', data.intercomOptions);
        }
        // Hubspot setup
        if (data?.user && !data.user.is_spark_staff) {
          const _hsq = (window._hsq = window._hsq || []);
          _hsq.push(['identify', { email: data.user.email }]);
        }
      } catch (error) {
        console.error(error);
        this.data = null;
      }
    },
    async logout() {
      try {
        await api.post('/logout');
      } catch (error) {
        console.error(error);
      }

      this.$reset();
    },
    async changeSite(teamId: string) {
      const envStore = useEnvStore();
      const settingsStore = useSettingsStore();
      const salesActivityStore = useSalesActivityStore();

      try {
        await api.post('/change-site', { team_id: teamId });
      } catch (err: any) {
        switch (err?.response?.status) {
          case 403:
            notificationStore.notify(
              'Error',
              err?.response?.data?.message ||
                `Sorry, you don't have permission to access this site.`,
              ERROR,
            );
            break;
          case 404:
            notificationStore.notify(
              'Error',
              `Sorry, we couldn't find the site you're looking for. Please try again.`,
              ERROR,
            );
            break;
        }
      }

      this.loadUser(true);

      envStore.$reset();
      settingsStore.$reset();
      salesActivityStore.$reset();
    },
    async getAvailableTeams() {
      if (this.data) {
        const { data } = await api.get('/available-teams');
        this.data.availableTeams = data ?? [];
      }
    },
    async searchAvailableTeamsAdvanced(search: string) {
      const { data } = await api.get('/search-available-teams-advanced', {
        params: {
          search,
        },
      });
      this.availableTeamsAdvanced = data ?? [];
    },
  },
});
