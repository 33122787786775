import '@/css/globals.css';

import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light-border.css';

import App from '@/App.vue';
import router from '@/router';
import { createGtm } from '@gtm-support/vue-gtm';
import * as Sentry from '@sentry/vue';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
// @ts-expect-error missing types
import shortkey from 'vue-three-shortkey';
import VueTippy from 'vue-tippy';

const app = createApp(App);

app.use(createPinia());
app.use(router);
app.use(shortkey);
app.use(
  createGtm({
    id: 'GTM-MQ26N647',
    vueRouter: router,
  }),
);
app.use(VueTippy, {
  defaultProps: {
    placement: 'bottom-start',
    theme: 'light-border',
    interactive: true,
  },
});

const { host } = window.location;
if (host === 'app.sparklayer.io' || host === 'app.dev.sparklayer.io') {
  Sentry.init({
    app,
    beforeBreadcrumb(breadcrumb) {
      if (breadcrumb?.data?.url?.includes('clarity.ms')) {
        return null;
      }
      return breadcrumb;
    },
    dsn: 'https://b2cd1b9778ed4f8abe25362246e2c35f@o932531.ingest.sentry.io/5881317',
    environment: host,
    ignoreErrors: [
      // Ignore network errors which are likely caused by user's unreliable
      // network connection
      'error loading dynamically imported module',
      'Failed to fetch dynamically imported module',
      'Load failed',
      'NetworkError',
      'Network Error',
      'Unable to preload CSS',

      // https://stackoverflow.com/a/50387233
      'ResizeObserver loop limit exceeded',

      'ResizeObserver loop completed with undelivered notifications.',
      'Request failed with status code 401',
      'Request failed with status code 419',
    ],
    integrations: [Sentry.browserTracingIntegration({ router })],
    logErrors: true,
    // __SENTRY_RELEASE__ is statically replaced at build time by Vite
    // eslint-disable-next-line no-undef
    release: __SENTRY_RELEASE__,
    tracesSampleRate: 0.1,
  });
}

app.mount('#app');
