import notificationStore, { ERROR } from '@/store/modules/notifications';
import useSettingsStore from '@/store/modules/settings';
import api from '@/utils/api';
import { defineStore } from 'pinia';

export type Env = {
  id: number;
  team_id: number;
  environment: string;
  db_created: number;
  platform: string;
  store_id: string;
  onboarding_platform: boolean;
  onboarding_api: boolean;
  onboarding_sync: boolean;
  onboarding_plus: boolean;
  onboarding_settings: boolean;
  onboarding_frontend: boolean;
  onboarding_launch: boolean;
  created_at: string;
  updated_at: string;
  platform_removed_at: any;
  sparkjs_version: string;
  team: {
    id: number;
    name: string;
    is_partner: boolean;
    slugged_name: string;
    website: string;
    app_registration_source: string;
    billing_plan_name: any;
    shopify_recurring_trial_ends_at: any;
    shopify_enterprise_billing: boolean;
    shopify_enterprise_planned_go_live: any;
    billing_custom_price: any;
    orders_allowed: any;
    shopify_enterprise_billing_no_of_teams_allowed: any;
    billing_linked_team_id: any;
    shopify_enterprise_billing_completed: number;
    sales_agents_allowed: any;
    billing_duration: any;
    billing_provider: string;
    advanced_features_enabled: boolean;
    enterprise_features_enabled: boolean;
    billing_plan_data: any;
    billing_linked_site_id: any;
    is_scheduled_for_deletion: boolean;
  };
  billing: {
    has_active_plan: boolean;
    trial_ends_at: string;
    trial_ends_in_days: number;
    has_trial_expired: boolean;
    is_enterprise_billing: boolean;
    provider: string;
    plan_name: string;
    plan_key: string;
    price_cents: number;
    sales_agents_allowed: number;
    orders_allowed: number;
    teams_allowed: any;
    is_deprecated: any;
    duration: string;
    is_advanced_features_enabled: boolean;
    has_pending_plan: boolean;
    allow_change_plan: boolean;
  };
  connected_platform: string;
};

type State = {
  siteId: string | null;
  siteEnv: string | null;
  env: Env | null;
};

export default defineStore('env', {
  state: (): State => ({
    siteId: null,
    siteEnv: null,
    env: null,
  }),
  getters: {
    isLoaded: (state) => state.env !== null,
    isLive: (state) => state.env?.environment === 'live',
    isSiteReady: (state) => !!state.env?.db_created,
    team: (state) => state.env?.team,
    billing: (state) => state.env?.billing,
    acronymTeamName: (state) =>
      state.env?.team.name.match(/\b\w/g)?.join('').toUpperCase().substring(0, 2),
    isAdvancedFeaturesEnabled: (state) => state.env?.billing?.is_advanced_features_enabled || false,
    isPartner: (state) => state.env?.team?.is_partner || false,
    plan: (state) => state.env?.billing?.plan_key,
    environment: (state) => state.env?.environment,
    // @todo long term remove the below but the backend doesn't care about plus or not
    // but the dashboard does
    syncLogPlatform: (state) =>
      state.env?.platform === 'shopify_plus' ? 'shopify' : state.env?.platform,
    isPlatform: (state) => (platformString: string) =>
      state.env?.connected_platform === platformString,
  },
  actions: {
    setData({ siteId, siteEnv, env }: State) {
      this.siteId = siteId;
      this.siteEnv = siteEnv;
      this.env = env;
    },
    async loadEnv(input?: { siteId: string; siteEnv: string }) {
      const settingsStore = useSettingsStore();

      let { siteId, siteEnv } = this;
      if (input?.siteId) {
        siteId = input.siteId;
      }
      if (input?.siteEnv) {
        siteEnv = input.siteEnv;
      }

      // Avoid loading environment if site id and site env have not changed
      if (input !== undefined && this.siteId === siteId && this.siteEnv === siteEnv) {
        return;
      }

      let env = null;

      try {
        const { data } = await api.get(`/${siteId}/${siteEnv}`);
        env = data;
      } catch (err: any) {
        notificationStore.notify(
          'Error',
          `Sorry an error has occurred, please try again. If you're still having issues, please contact our support team.`,
          ERROR,
        );
        return;
      }

      this.setData({ siteId, siteEnv, env });

      if (this.isSiteReady) {
        settingsStore.loadSettings(env);
      }
    },
  },
});
