import useAuthStore from '@/store/modules/auth';
import useEnvStore from '@/store/modules/env';
import { nextTick } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';

const redirectWithParams =
  (name: string) =>
  ({ params }: { params: any }) => ({
    name,
    params,
  });

const routes = [
  {
    path: '/',
    name: 'home',
    // @ts-expect-error no types
    component: () => import('../views/Redirect.vue'),
    meta: { auth: true },
  },
  {
    path: '/getting-ready',
    name: 'Getting Ready',
    // @ts-expect-error no types
    component: () => import('../views/GettingReady.vue'),
    meta: { auth: true },
  },
  {
    path: '/:siteId',
    name: 'team-redirect',
    // @ts-expect-error no types
    component: () => import('../views/Redirect.vue'),
    meta: { auth: true, redirectName: 'home' },
  },
  {
    path: '/:siteId/partners',
    name: 'partners',
    // @ts-expect-error no types
    component: () => import('../views/Partners/Main.vue'),
    meta: { auth: true },
    children: [
      {
        path: '',
        name: 'partners-redirect',
        redirect: redirectWithParams('partner-overview'),
      },
      {
        path: 'overview',
        name: 'partner-overview',
        // @ts-expect-error no types
        component: () => import('../views/Partners/Overview.vue'),
        meta: {
          auth: true,
          name: 'Partner Dashboard',
        },
      },
      {
        path: 'details',
        name: 'partner-referral',
        // @ts-expect-error no types
        component: () => import('../views/Partners/Referrals.vue'),
        meta: {
          auth: true,
          name: 'Partner Referral',
        },
      },
      {
        path: 'downloads',
        name: 'partner-resources',
        // @ts-expect-error no types
        component: () => import('../views/Partners/Downloads.vue'),
        meta: {
          auth: true,
          name: 'Partner Resources',
        },
      },
    ],
  },
  {
    path: '/:siteId/:siteEnv',
    name: 'team-overview',
    // @ts-expect-error no types
    component: () => import('../views/Home.vue'),
    meta: { auth: true },
  },
  {
    path: '/:siteId/:siteEnv/orders',
    name: 'orders',
    // @ts-expect-error no types
    component: () => import('../views/SalesActivity/SalesActivity.vue'),
    meta: {
      auth: true,
      name: 'Orders',
    },
  },
  {
    path: '/:siteId/:siteEnv/configuration',
    name: 'configuration',
    // @ts-expect-error no types
    component: () => import('../views/Settings/Settings.vue'),
    meta: { auth: true },
    children: [
      {
        path: '',
        name: 'config-redirect',
        redirect: redirectWithParams('config-onboarding'),
      },
      {
        path: 'onboarding',
        name: 'config-onboarding',
        // @ts-expect-error no types
        component: () => import('../views/Settings/SettingsSetup.vue'),
        meta: {
          auth: true,
          name: 'Setup - Settings',
        },
      },
      {
        path: 'integrations',
        name: 'integrations',
        // @ts-expect-error no types
        component: () => import('../views/Settings/SettingsIntegrations.vue'),
        children: [
          {
            path: '',
            redirect: redirectWithParams('config-int-platform'),
            name: 'integration-redirect',
          },
          {
            path: 'platform',
            name: 'config-int-platform',
            // @ts-expect-error no types
            component: () => import('../views/Settings/SettingsIntegrationsPlatform.vue'),
            meta: {
              auth: true,
              name: 'Integrations (eCommerce Platform) - Settings',
            },
          },
          {
            path: 'partners',
            name: 'config-int-partners',
            // @ts-expect-error no types
            component: () => import('../views/Settings/SettingsIntegrationsPartners.vue'),
            meta: {
              auth: true,
              name: 'Integrations (Partner Integrations) - Settings',
            },
          },
          {
            path: 'oauth-brightpearl',
            name: 'config-int-brightpearl',
            // @ts-expect-error no types
            component: () => import('../views/Settings/OAuth/Brightpearl.vue'),
            meta: {
              auth: true,
              name: 'Brightpearl - Integrations (Partner Integrations) - Settings',
            },
          },
        ],
      },
      {
        path: 'api',
        name: 'config-api',
        // @ts-expect-error no types
        component: () => import('../views/Settings/SettingsAPI.vue'),
        meta: {
          auth: true,
          name: 'API - Settings',
        },
      },
      {
        path: 'frontend',
        name: 'config-frontend',
        // @ts-expect-error no types
        component: () => import('../views/Settings/SettingsWidgets.vue'),
        meta: { auth: true },
        children: [
          {
            path: '',
            name: 'config-frontend-redirect',
            redirect: redirectWithParams('config-frontend-core'),
          },
          {
            path: 'core',
            name: 'config-frontend-core',
            // @ts-expect-error no types
            component: () => import('../views/Settings/SettingsWidgetsCore.vue'),
            meta: {
              auth: true,
              name: 'Widgets (Core script) - Settings',
            },
          },
          {
            path: 'pdp',
            name: 'config-frontend-pdp',
            // @ts-expect-error no types
            component: () => import('../views/Settings/SettingsWidgetsPDP.vue'),
            meta: {
              auth: true,
              name: 'Widgets (Product page script) - Settings',
            },
          },
          {
            path: 'css',
            name: 'config-frontend-css',
            // @ts-expect-error no types
            component: () => import('../views/Settings/SettingsWidgetsCSS.vue'),
            meta: {
              auth: true,
              name: 'Widgets (Custom design) - Settings',
            },
          },
          {
            path: 'tips',
            name: 'config-frontend-tips',
            // @ts-expect-error no types
            component: () => import('../views/Settings/SettingsWidgetsTips.vue'),
            meta: {
              auth: true,
              name: 'Widgets (Best-practice tips) - Settings',
            },
          },
        ],
      },
      {
        path: 'shipping',
        name: 'config-shipping',
        // @ts-expect-error no types
        component: () => import('../views/Settings/SettingsShippingRates.vue'),
        meta: {
          auth: true,
          name: 'Shipping - Settings',
        },
      },
      {
        path: 'theme',
        name: 'config-theme',
        // @ts-expect-error no types
        component: () => import('../views/Settings/SettingsTheme.vue'),
        meta: {
          auth: true,
          name: 'Theme - Settings',
        },
      },
      {
        path: 'settings',
        name: 'config-settings',
        // @ts-expect-error no types
        component: () => import('../views/Settings/SettingsConfigurations.vue'),
        meta: { auth: true },
        children: [
          {
            path: '',
            redirect: redirectWithParams('config-settings-general'),
            name: 'config-settings-redirect',
          },
          {
            path: 'general',
            name: 'config-settings-general',
            // @ts-expect-error no types
            component: () => import('../views/Settings/SettingsConfigurationsGeneral.vue'),
            meta: {
              auth: true,
              name: 'Configurations - Settings',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/:siteId/:siteEnv/configuration/theme/editor/:themeId/:slug?',
    name: 'theme-editor',
    // @ts-expect-error no types
    component: () => import('../views/Settings/partialsTheme/ThemeEditor.vue'),
    meta: {
      auth: true,
      name: 'Theme Editor',
    },
  },
  {
    path: '/:siteId/settings',
    name: 'settings',
    // @ts-expect-error no types
    component: () => import('../views/Account/Settings.vue'),
    meta: { auth: true },
    children: [
      {
        path: '',
        redirect: redirectWithParams('settings-account'),
        name: 'settings-redirect',
      },
      {
        path: 'account',
        name: 'settings-account',
        // @ts-expect-error no types
        component: () => import('../views/Account/SettingsAccount.vue'),
        meta: {
          auth: true,
          name: 'Account - Settings',
        },
      },
      {
        path: 'password',
        name: 'settings-password',
        // @ts-expect-error no types
        component: () => import('../views/Account/SettingsPassword.vue'),
        meta: {
          auth: true,
          name: 'Password - Settings',
        },
      },
      {
        path: 'team',
        name: 'settings-team',
        // @ts-expect-error no types
        component: () => import('../views/Account/SettingsTeam.vue'),
        meta: {
          auth: true,
          name: 'Team - Settings',
        },
      },
      {
        path: 'plan',
        name: 'settings-plan',
        // @ts-expect-error no types
        component: () => import('../views/Account/SettingsBillingPlan.vue'),
        meta: {
          auth: true,
          name: 'Plan - Settings',
        },
      },
    ],
  },
  {
    path: '/:siteId/staff',
    name: 'staff',
    // @ts-expect-error no types
    component: () => import('../views/Staff/Staff.vue'),
    meta: { auth: true, staffOnly: true },
    children: [
      {
        path: '',
        redirect: redirectWithParams('staff-manage-billing'),
        name: 'staff-redirect',
      },
      {
        path: 'manage-billing',
        name: 'staff-manage-billing',
        // @ts-expect-error no types
        component: () => import('../views/Staff/Billing/ManageBilling.vue'),
        meta: {
          auth: true,
          name: 'Staff - Manage Billing',
        },
      },
      {
        path: 'manage-billing/:actionType',
        name: 'staff-manage-billing-action',
        // @ts-expect-error no types
        component: () => import('../views/Staff/Billing/ViewAction.vue'),
        meta: {
          auth: true,
          name: 'Staff - Manage Billing',
        },
      },
      {
        path: 'manage-team',
        name: 'staff-manage-team',
        // @ts-expect-error no types
        component: () => import('../views/Staff/Team/ManageTeam.vue'),
        meta: {
          auth: true,
          name: 'Staff - Manage Team',
        },
      },
      {
        path: 'manage-team/:actionType',
        name: 'staff-manage-team-action',
        // @ts-expect-error no types
        component: () => import('../views/Staff/Team/ViewAction.vue'),
        meta: {
          auth: true,
          name: 'Staff - Manage Team',
        },
      },
    ],
  },
  {
    path: '/:siteId/:siteEnv/pricing',
    name: 'products',
    // @ts-expect-error no types
    component: () => import('../views/Prices/MainLayout.vue'),
    meta: { auth: true },
    children: [
      {
        path: '',
        redirect: redirectWithParams('pricing-lists'),
        name: 'products-redirect',
      },
      {
        path: 'lists',
        name: 'pricing-lists',
        // @ts-expect-error no types
        component: () => import('../views/Prices/PriceLists.vue'),
        meta: {
          auth: true,
          name: 'Price Lists',
        },
      },
      {
        path: 'editor/:variantId?',
        name: 'pricing-editor',
        // @ts-expect-error no types
        component: () => import('../views/Prices/PriceEditor.vue'),
        meta: {
          auth: true,
          name: 'Price Editor',
        },
      },
    ],
  },
  {
    path: '/:siteId/:siteEnv/data-sync',
    name: 'data-sync',
    // @ts-expect-error no types
    component: () => import('../views/DataSync/Main.vue'),
    meta: { auth: true },
    children: [
      {
        path: '',
        redirect: redirectWithParams('sync-products'),
        name: 'data-sync-redirect',
      },
      {
        path: 'products/:variantId?',
        name: 'sync-products',
        // @ts-expect-error no types
        component: () => import('../views/DataSync/Products.vue'),
        meta: {
          auth: true,
          name: 'Data Sync - Products',
        },
      },
      {
        path: 'customers',
        name: 'sync-customers',
        // @ts-expect-error no types
        component: () => import('../views/DataSync/Customers.vue'),
        meta: {
          auth: true,
          name: 'Data Sync - Customers',
        },
      },
    ],
  },
  {
    path: '/:siteId/:siteEnv/discounts',
    name: 'discounts',
    // @ts-expect-error no types
    component: () => import('../views/Discounts/Layout.vue'),
    meta: { auth: true },
    children: [
      {
        path: '',
        name: 'discounts-list',
        // @ts-expect-error no types
        component: () => import('../views/Discounts/List.vue'),
        meta: {
          auth: true,
          name: 'Discounts',
        },
      },
    ],
  },
  {
    path: '/:siteId/:siteEnv/customers',
    name: 'customers',
    // @ts-expect-error no types
    component: () => import('../views/Customers/Main.vue'),
    meta: { auth: true },
    children: [
      {
        path: '',
        redirect: redirectWithParams('customers-groups'),
        name: 'customers-redirect',
      },
      {
        path: 'groups',
        name: 'customers-groups',
        // @ts-expect-error no types
        component: () => import('../views/Customers/Groups.vue'),
        meta: {
          auth: true,
          name: 'Customer Groups - Customer',
        },
      },
    ],
  },
  {
    path: '/:siteId/:siteEnv/sales-agents',
    name: 'sales-agents',
    // @ts-expect-error no types
    component: () => import('../views/SalesAgents/SalesAgents.vue'),
    meta: {
      auth: true,
      name: 'Sales Agents',
    },
  },
  {
    path: '/help',
    name: 'help',
    // @ts-expect-error no types
    component: () => import('../views/Help.vue'),
    meta: {
      auth: true,
      name: 'Help',
    },
  },
  {
    path: '/partner-register',
    name: 'register-partner',
    // @ts-expect-error no types
    component: () => import('../views/Partners/Register.vue'),
    meta: {
      auth: false,
      name: 'Partner Register',
    },
  },
  {
    path: '/register-team-member',
    name: 'register-team-member',
    // @ts-expect-error no types
    component: () => import('../views/RegisterTeamMember.vue'),
    meta: {
      auth: false,
      name: 'Register Team Member',
    },
  },
  {
    path: '/login',
    name: 'login',
    // @ts-expect-error no types
    component: () => import('../views/Login.vue'),
    meta: {
      auth: false,
      name: 'Login',
    },
  },
  {
    path: '/logout',
    name: 'logout',
    // @ts-expect-error no types
    component: () => import('../views/Logout.vue'),
    meta: {
      auth: true,
      name: 'Logout',
    },
  },
  {
    path: '/register',
    name: 'register',
    // @ts-expect-error no types
    component: () => import('../views/Register.vue'),
    meta: {
      auth: false,
      name: 'Register',
    },
  },
  {
    path: '/go-register',
    name: 'register-sales',
    redirect: '/register?app_registration_source=sales_pipeline_tracked_link',
    meta: {
      auth: false,
      name: 'Register - Sales Pipeline Redirect',
    },
  },
  {
    path: '/go-enterprise',
    name: 'register-enterprise',
    // @ts-expect-error no types
    component: () => import('../views/RegisterEnterprise.vue'),
    meta: {
      auth: false,
      name: 'Register - Enterprise',
    },
  },
  {
    path: '/register-invite',
    name: 'register-invite',
    // @ts-expect-error no types
    component: () => import('../views/RegisterInvite.vue'),
    meta: {
      auth: false,
      name: 'Invite to Register',
    },
  },
  {
    path: '/no-team',
    name: 'no-team',
    // @ts-expect-error no types
    component: () => import('../views/NoTeam.vue'),
    meta: {
      auth: true,
      name: 'No Team',
    },
  },
  {
    path: '/forgotten-password',
    name: 'forgotten-password',
    // @ts-expect-error no types
    component: () => import('../views/ForgottenPassword.vue'),
    meta: {
      auth: false,
      name: 'Forgotten Password',
    },
  },
  {
    path: '/reset-password/:token',
    name: 'reset-password',
    // @ts-expect-error no types
    component: () => import('../views/ResetPassword.vue'),
    meta: {
      auth: false,
      name: 'Reset Password',
    },
  },
  {
    path: '/switch-account/:teamId',
    name: 'switch-account',
    component: () => import('../views/SwitchAccount.vue'),
    meta: {
      auth: true,
      name: 'Switch Account',
    },
  },
];

// Below creates extra routes for any routes with following pre-fixes:
// - /:siteId/:siteEnv
// routes are setup to redirect correctly with siteId/siteEnv in the path
const routesWithSiteIdRedirect: any[] = [];
routes
  .filter((x) => x.path.startsWith('/:siteId/'))
  .forEach((topPath) => {
    routesWithSiteIdRedirect.push({
      path: topPath.path,
      name: topPath.name,
    });
    topPath?.children?.forEach((childPath) => {
      routesWithSiteIdRedirect.push({
        path: `${topPath.path}/${childPath.path}`,
        name: childPath.name,
      });

      // @ts-expect-error children is not defined
      childPath?.children?.forEach((childChildPath: any) =>
        routesWithSiteIdRedirect.push({
          path: `${topPath.path}/${childPath.path}/${childChildPath.path}`,
          name: childChildPath.name,
        }),
      );
    });
  });

routes.push(
  // @ts-expect-error mismatched types
  ...routesWithSiteIdRedirect.map((x: any) => {
    if (!x.name) {
      console.error(`All routes must be setup with a route name ${x.path}`);
    }
    const path = x.path.replace('/:siteId', '').replace('/:siteEnv', '');
    if (!path) {
      return {};
    }
    return {
      path,
      // @ts-expect-error no types
      component: () => import('../views/Redirect.vue'),
      meta: {
        auth: true,
        _note: 'this is a generated route, see router/index.ts',
        redirectName: x.name,
      },
    };
  }),
);

// finally push the thankful 404
// @ts-expect-error mismatched types
routes.push({
  path: '/:pathMatch(.*)*',
  name: 'four-oh-four',
  redirect: '/',
  meta: { auth: true, redirectName: 'home' },
});

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

const DEFAULT_TITLE = 'SparkLayer Dashboard';
router.afterEach(({ meta }) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  nextTick(() => {
    document.title = meta?.name ? `${meta.name} - ${DEFAULT_TITLE}` : DEFAULT_TITLE;
  });
});

router.afterEach(({ path }) => {
  window.Intercom('update');
  const _hsq = (window._hsq = window._hsq || []);
  _hsq.push(['setPath', path]);
  _hsq.push(['trackPageView']);
});

router.beforeEach(async ({ meta, path, params, query }, _from, next) => {
  const envStore = useEnvStore();
  const authStore = useAuthStore();

  const { auth, redirectName, staffOnly } = meta as {
    auth: boolean;
    redirectName: string;
    staffOnly: boolean;
  };
  await authStore.loadUser();
  const isLoggedIn = authStore.isLoggedIn;
  const currentTeam = authStore.data?.currentTeam ?? null;
  const hasTeam = currentTeam !== null;
  const isSparkStaff = authStore.isSparkStaff;

  // Redirect logged in users away from logged out urls
  if (
    isLoggedIn &&
    (path === '/login' ||
      path === '/register' ||
      path === '/go-enterprise' ||
      path === '/partner-register')
  ) {
    next({
      name: 'home',
    });
    return;
  }

  // Pre-initial checks
  switch (true) {
    case !auth && !isLoggedIn:
      // If no auth is needed and no login is present continue with out any setup
      next();
      return;
    case auth && !isLoggedIn:
      // If auth is required and the user is not logged in, push to login
      next({
        name: 'login',
        query: { from: window.location.pathname },
      });
      return;
    case staffOnly && !isSparkStaff:
      next({
        name: 'home',
      });
      return;
    case path !== '/no-team' && auth && !hasTeam:
      next({ name: 'no-team' });
      return;
  }

  // Load team / team environment
  // First from the routing params if provided
  if (params?.siteId && params?.siteEnv) {
    await envStore.loadEnv({
      siteId: params.siteId as string,
      siteEnv: params.siteEnv as string,
    });
  } else if (!envStore.isLoaded && currentTeam) {
    // Otherwise let's load the default for the user logged in
    const currentTeamIsPartner = currentTeam.is_partner;
    const currentTeamSiteId = currentTeam.slugged_name;
    await envStore.loadEnv({
      siteId: currentTeamSiteId,
      siteEnv: currentTeamIsPartner ? 'test' : 'live',
    });
  }

  const isSiteReady = envStore.isSiteReady;
  if (path !== '/getting-ready' && !isSiteReady && hasTeam) {
    next({ name: 'Getting Ready' });
    return;
  }

  // Redirect route?
  if (redirectName) {
    const env = envStore.env;
    next({
      name: redirectName,
      params: {
        siteId: env?.team.slugged_name as string,
        siteEnv: env?.environment as string,
      },
      query,
    });
    return;
  }

  next();
});

export default router;
